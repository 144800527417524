import { useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import {
  Button,
  Card,
  Checkbox,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFetch } from "../../hooks/useFetch";
import "./index.scss";
import { toast } from "react-toastify";
import signatureImg from "../../pdf/assets/signature-OCR.webp";
import { Iconify, Loader } from "..";
import { useSelector } from "react-redux";

const attachmentsFieldBook = [
  { name: "quotation", label: "Devis" },
  { name: "first_invoice", label: "1ère facture" },
  { name: "second_invoice", label: "2ème facture" },
  { name: "third_invoice", label: "3ème facture" },
  { name: "signed_mandate", label: "Mandat signé" },
  { name: "signed_quotation", label: "Devis signé" },
  { name: "big_plan", label: "Plan général" },
  { name: "dwg_plan", label: "Plan DWG" },
  { name: "cadastral_plan", label: "Plan cadastral" },
  { name: "urban_authorization", label: "Autorisation d'urbanisme" },
  { name: "summary_plan", label: "Plan de synthèse" },
  { name: "situation_plan", label: "Plan de situation" },
  { name: "meter_plan", label: "Plan de branchement" },
  { name: "return_dt", label: "Retour DT" },
  { name: "request_files", label: "Fichiers de demande" },
];

const attachmentsSiteConnection = [
  { name: "quotation", label: "Devis" },
  { name: "signed_quotation", label: "Devis signé" },
  { name: "study", label: "Etude" },
  { name: "study_return", label: "Retour étude" },
  { name: "reception", label: "Plan de récolement" },
  { name: "documents", label: "Documents" },
];

const mailsSender = [
  "lbossard@ouest-concept-reseaux.fr",
  "be@ouest-concept-reseaux.fr",
  "travaux@ouest-concept-reseaux.fr",
  "secretariat@ouest-concept-reseaux.fr",
];

const Mail = ({
  fieldBook: fieldBookInit,
  siteConnection: siteConnectionInit,
  filesSiteConnection: filesSiteConnectionInit,
  recipient: recipientInit,
  subject: subjectInit,
  body: bodyInit,
  files: filesFieldBookInit,
  generatedFile,
  generatedFileName,
  signature = false,
}) => {
  const user = useSelector((state) => state.user);

  const { isLoading, responseData, post } = useFetch(true);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [open, setOpen] = useState(false);
  const [sender, setSender] = useState(
    user?.role === 3 ? mailsSender[2] : mailsSender[0]
  );
  const [recipients, setRecipients] = useState(
    recipientInit ? [recipientInit] : []
  );
  const [subject, setSubject] = useState(subjectInit);
  const [body, setBody] = useState(
    `${bodyInit} ${
      signature
        ? `\n\n<img src="https://reseaux-plus.com${signatureImg}" alt="signature" style="width: 150px; height: auto;"/>\n\n`
        : ""
    }`
  );
  const [fileBlob, setFileBlob] = useState();
  const [sendFileBlob, setSendFileBlob] = useState(
    generatedFile ? true : false
  );
  const [fieldBook, setFieldBook] = useState(fieldBookInit);
  const [siteConnection, setSiteConnection] = useState(siteConnectionInit);
  const [filesFieldBook, setFilesFieldBook] = useState(
    fieldBookInit && filesFieldBookInit
      ? fieldBookInit[filesFieldBookInit]?.map((file) => file.id)
      : []
  );
  const [filesSiteConnection, setFilesSiteConnection] = useState(
    siteConnectionInit && filesSiteConnectionInit
      ? [siteConnectionInit[filesSiteConnectionInit]?.id]
      : []
  );
  const [filesUpload, setFilesUpload] = useState([]);
  const [attachmentNumber, setAttachmentNumber] = useState(0);

  useEffect(() => {
    setFieldBook(fieldBookInit);
    setFilesFieldBook(
      fieldBookInit && filesFieldBookInit
        ? fieldBookInit[filesFieldBookInit]?.map((file) => file.id)
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldBookInit]);

  useEffect(() => {
    setSiteConnection(siteConnectionInit);
    setFilesSiteConnection(
      siteConnectionInit && filesSiteConnectionInit
        ? [siteConnectionInit[filesSiteConnectionInit]?.id]
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteConnectionInit]);

  useEffect(() => {
    let result =
      Number(filesFieldBook?.length || 0) +
      Number(filesSiteConnection?.length || 0) +
      Number(filesUpload?.length || 0) +
      Number(sendFileBlob ? 1 : 0);
    setAttachmentNumber(result);
  }, [filesFieldBook, filesSiteConnection, filesUpload, sendFileBlob]);

  useEffect(() => {
    if (responseData) {
      responseData.message === "Email sent"
        ? toast.success("Le mail à bien été envoyé")
        : toast.error("Une erreur est survenue");
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  useEffect(() => {
    const blobFetch = async () => {
      try {
        const result = await pdf(await generatedFile).toBlob();
        setFileBlob(result);
      } catch (error) {
        console.log(error);
      }
    };
    if (generatedFile) {
      blobFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedFile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("mailers[sender]", sender);
    formData.append("mailers[recipients]", recipients.join(", "));
    formData.append("mailers[subject]", subject);
    formData.append("mailers[body]", body.replace(/\n/g, "<br />"));

    filesFieldBook.forEach((file) => {
      formData.append("mailers[filesBlobIds][]", file);
    });

    filesSiteConnection.forEach((file) => {
      formData.append("mailers[filesBlobIds][]", file);
    });

    if (filesUpload.length > 0) {
      for (let i = 0; i < filesUpload.length; i++) {
        formData.append("mailers[files][]", filesUpload[i]);
      }
    }

    if (sendFileBlob) {
      if (fileBlob) {
        formData.append(
          "mailers[generatedFile]",
          fileBlob,
          generatedFileName || "piece_jointe.pdf"
        );
      }
    }

    post("mailers", formData, false);
  };

  const handleClose = () => {
    setSender(mailsSender[0]);
    setRecipients(recipientInit ? [recipientInit] : []);
    setSubject(subjectInit);
    setBody(
      `${bodyInit} ${
        signature
          ? `\n\n<img src="https://reseaux-plus.com${signatureImg}" alt="signature" style="width: 150px; height: auto;"/>\n\n`
          : ""
      }`
    );
    setFilesFieldBook(
      fieldBookInit && filesFieldBookInit
        ? fieldBookInit[filesFieldBookInit]?.map((file) => file.id)
        : []
    );
    setFilesSiteConnection(
      siteConnectionInit && filesSiteConnectionInit
        ? [siteConnectionInit[filesSiteConnectionInit]?.id]
        : []
    );
    setFilesUpload([]);
    setAttachmentNumber(
      filesFieldBook?.length +
        filesSiteConnection?.length +
        filesUpload?.length +
        (sendFileBlob ? 1 : 0)
    );
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <img
          src="/assets/icons/ic_email.png"
          alt="mail"
          width="20px"
          height="20px"
        />
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Card
          className="mail_modal"
          style={{
            height: "fit-content",
            maxHeight: "95vh",
            width: "fit-content",
            maxWidth: "95vw",
            overflowY: "auto",
            padding: "20px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Stack gap={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Envoyer un email</Typography>
              <Button variant="contained" color="error" onClick={handleClose}>
                <Iconify icon="eva:close-fill" />
              </Button>
            </Stack>
            <form onSubmit={handleSubmit}>
              <Stack direction="column" gap={3}>
                <Stack>
                  <label htmlFor="recipients">Expéditeur</label>
                  <Select
                    label="Expéditeur"
                    value={sender}
                    onChange={(e) => setSender(e.target.value)}
                  >
                    {mailsSender.map((sender) => (
                      <MenuItem key={sender} value={sender}>
                        {sender}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack>
                  <label htmlFor="recipients">Destinataire(s)</label>
                  <ReactMultiEmail
                    placeholder="Destinataire"
                    emails={recipients}
                    onChange={(_emails) => {
                      setRecipients(_emails);
                    }}
                    autoFocus={true}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </Stack>
                <TextField
                  label="Sujet"
                  variant="outlined"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <label htmlFor="files">
                      Pièces jointes ({attachmentNumber})
                    </label>
                    <Button onClick={() => setOpenAttachment(!openAttachment)}>
                      <img
                        src={`/assets/icons/ic_${
                          openAttachment ? "down-arrow" : "up-arrow"
                        }.png`}
                        alt="mail"
                        width="18px"
                        height="18px"
                      />
                    </Button>
                  </Stack>

                  {openAttachment && (
                    <Stack p={1.5} pt={0}>
                      <Typography variant="h4" style={{ fontSize: "1rem" }}>
                        Dossier :
                      </Typography>

                      {/* PDFs générés */}
                      {fileBlob && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          ml={2}
                        >
                          <Typography>
                            - {generatedFileName || "piece_jointe.pdf"}
                          </Typography>

                          <Checkbox
                            checked={sendFileBlob}
                            onChange={() => setSendFileBlob(!sendFileBlob)}
                          />
                        </Stack>
                      )}

                      {/* PDFs serveurs */}
                      {fieldBook &&
                        attachmentsFieldBook.map((attachment, index) => (
                          <Stack key={index}>
                            {fieldBook[attachment.name] && (
                              <Stack>
                                <Typography
                                  variant="h4"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  - {attachment.label}
                                </Typography>
                                {fieldBook[attachment.name].map(
                                  (file, index) => (
                                    <Stack
                                      key={index}
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      ml={2}
                                    >
                                      <Typography
                                        style={{ fontSize: "0.9rem" }}
                                      >
                                        - {file.name}
                                      </Typography>
                                      <Checkbox
                                        checked={filesFieldBook.includes(
                                          file.id
                                        )}
                                        onChange={() => {
                                          if (
                                            filesFieldBook.includes(file.id)
                                          ) {
                                            setFilesFieldBook(
                                              filesFieldBook.filter(
                                                (f) => f !== file.id
                                              )
                                            );
                                          } else {
                                            setFilesFieldBook([
                                              ...filesFieldBook,
                                              file.id,
                                            ]);
                                          }
                                        }}
                                      />
                                    </Stack>
                                  )
                                )}
                              </Stack>
                            )}
                          </Stack>
                        ))}

                      {siteConnection && (
                        <>
                          <Typography variant="h4" style={{ fontSize: "1rem" }}>
                            Chantier :
                          </Typography>
                          {attachmentsSiteConnection.map(
                            (attachment, index) => (
                              <Stack key={index}>
                                {siteConnection[attachment.name] && (
                                  <Stack>
                                    <Typography
                                      variant="h4"
                                      style={{ fontSize: "0.9rem" }}
                                    >
                                      - {attachment.label}
                                    </Typography>
                                    <Stack
                                      key={index}
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      ml={2}
                                    >
                                      <Typography
                                        style={{ fontSize: "0.9rem" }}
                                      >
                                        - {siteConnection[attachment.name].name}
                                      </Typography>
                                      <Checkbox
                                        checked={filesSiteConnection.includes(
                                          siteConnection[attachment.name].id
                                        )}
                                        onChange={() => {
                                          if (
                                            filesSiteConnection.includes(
                                              siteConnection[attachment.name].id
                                            )
                                          ) {
                                            setFilesSiteConnection(
                                              filesSiteConnection.filter(
                                                (f) =>
                                                  f !==
                                                  siteConnection[
                                                    attachment.name
                                                  ].id
                                              )
                                            );
                                          } else {
                                            setFilesSiteConnection([
                                              ...filesSiteConnection,
                                              siteConnection[attachment.name]
                                                .id,
                                            ]);
                                          }
                                        }}
                                      />
                                    </Stack>
                                  </Stack>
                                )}
                              </Stack>
                            )
                          )}
                        </>
                      )}

                      {/* PDFs uploadés */}
                      {filesUpload?.length > 0 && (
                        <Typography variant="h4" style={{ fontSize: "1rem" }}>
                          Ajoutées :
                        </Typography>
                      )}

                      {filesUpload &&
                        filesUpload.map((file, index) => (
                          <Stack
                            key={index}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            ml={2}
                          >
                            <Typography>- {file.name}</Typography>
                            <Button
                              onClick={() =>
                                setFilesUpload(
                                  filesUpload.filter((f, i) => i !== index)
                                )
                              }
                            >
                              <img
                                src={`/assets/icons/ic_trash-can.png`}
                                alt="mail"
                                width="18px"
                                height="18px"
                              />
                            </Button>
                          </Stack>
                        ))}
                      <input
                        type="file"
                        accept="application/pdf"
                        multiple
                        onChange={(e) => {
                          setFilesUpload([...filesUpload, ...e.target.files]);
                        }}
                      />
                    </Stack>
                  )}
                </Stack>
                <TextField
                  label="Corps du mail"
                  variant="outlined"
                  multiline
                  rows={10}
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                />
                {isLoading ? (
                  <Stack alignItems={"center"}>
                    <Loader />
                  </Stack>
                ) : (
                  <Stack alignItems="center">
                    <Button
                      type="submit"
                      disabled={
                        !recipients.length ||
                        !subject.length ||
                        !body.length ||
                        (generatedFile && !fileBlob)
                      }
                      variant="contained"
                    >
                      Envoyer
                    </Button>
                  </Stack>
                )}
              </Stack>
            </form>
          </Stack>
        </Card>
      </Modal>
    </>
  );
};

export default Mail;
