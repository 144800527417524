import { Button, Stack, Typography } from "@mui/material";
import React from "react";

const Phone = ({ phoneNumber }) => {
  return (
    <>
      {phoneNumber && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          style={{ width: "100%" }}
        >
          <Typography variant="p" style={{ whiteSpace: 'nowrap' }}>
            {phoneNumber.match(/\d{2}/g).join(" ")}
          </Typography>
          <Button onClick={() => (window.location = `tel:${phoneNumber}`)} sx={{ width: "fit-content" }}>
            <img
              src="/assets/icons/ic_phone.png"
              alt="phone"
              style={{ width: "20px", height: "20px" }}
            />
          </Button>
        </Stack>
      )}
    </>
  );
};

export default Phone;
